import React from "react";

function Navbar() {
  return (
    <header className="bg-gray-800 md:sticky top-0 z-10">
      <div className="container mx-auto flex flex-wrap p-2 flex-col md:flex-row items-center">
        <div className="flex justify-center p-2 items-center lg:ml-3 lg:mr-14">
          <a
            href="#about"
            className="title-font font-medium text-white text-xl  "
          >
            Abdullah Alsabi
          </a>
        </div>
        <div className="flex justify-center p-2 items-center">
          <a
            href="#certs"
            className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0"
          >
            Certificates 
          </a>
          <a
            href="#projects"
            className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0"
          >
            Projects
          </a>
          <a
            href="#skills"
            className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0"
          >
            Skills
          </a>
          <a
            href="#contact"
            className="inline-flex items-center bg-gray-800 border-0 py-1 px-3 focus:outline-none hover:bg-gray-700 rounded text-base mt-4 md:mt-0"
          >
            Contact
          </a>
        </div>
      </div>
    </header>
  );
}

export default Navbar;
