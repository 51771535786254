export const projects = [
  {
    title: "Coins Tracker",
    subtitle: "MERN Stack",
    description:
      "Coins tracker allows the user to track his transactions and get more details about his portfolio any time any where.",
    image:
      "https://res.cloudinary.com/abdullah-s/image/upload/v1658821469/coins-tracker.herokuapp.com__fzt1j5.png",
    link: "https://coins-tracker.alsabi.me/",
  },
  {
    title: "Fitness Tracker",
    subtitle: "MERN Stack",
    description:
      "Fitness Tracker allows users to take fitness classes wherever and whenever they want. And every user have his own dashboard for his progress.",
    image:
      "https://res.cloudinary.com/abdullah-s/image/upload/v1658821502/fitnessappdemo.herokuapp.com__kdwtkf.png",
    link: "https://fitnessapp.alsabi.me/",
  },
  {
    title: "Weather App",
    subtitle: "JS - HTML - CSS",
    description:
      "Weather App allows the user to find the weather status for any city around the world.",
    image:
      "https://res.cloudinary.com/abdullah-s/image/upload/v1658821522/resonsive-weather-app.netlify.app__yga7pi.png",
    link: "https://weather-app.alsabi.me/",
  },
  {
    title: "Youtube Clone",
    subtitle: "JS - HTML - CSS - Redux",
    description:
      "Abdullah's Youtube allows users to search and watch any video exists in Youtube API.",
    image:
      "https://res.cloudinary.com/abdullah-s/image/upload/v1658821502/abdullah-youtube.netlify.app__qjevuz.png",
    link: "https://abdullah-youtube.alsabi.me/",
  },
];

//opinion about my works by customers
// export const testimonials = [
//   {
//     quote:
//       "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
//     image: "https://randomuser.me/api/portraits/men/1.jpg",
//     name: "Jesse Hicks",
//     company: "Zoozle",
//   },
//   {
//     quote:
//       "Lorem ipsum dolor sit amet consectetur adipisicing elit. Culpa quod necessitatibus quidem impedit facilis? Distinctio laboriosam veritatis recusandae minus. Voluptatum accusantium hic laudantium soluta ea sapiente cupiditate officia maiores itaque.",
//     image: "https://randomuser.me/api/portraits/men/94.jpg",
//     name: "Ruben Alvarez",
//     company: "Dooble.io",
//   },
// ];

export const skills = [
  "AWS",
  "Alibaba Cloud",
  "Docker",
  "Kubernetes",
  "OpenShift",
  "Red Hat Quay",
  "Jenkins",
  "Argo CD",
  "Ansible",
  "Grafana",
  "Prometheus",
  "Infrastructure as Code",
  "Linux",
  "Bash",
  "Automation",
  "Computer networking",
  "Information security",
  "JavaScript",
  "Python",
  "React.js",
  "Tailwind",
  "APIs",
  "Redux",
  "Git",
  "GitLab",
  "MongoDB",
  "MySQL",
  "Redis",
];

export const Certifications = [
  {
    name: "AWS Certified Solutions Architect – Professional",
    date: "05/2024",
    image:
      "https://res.cloudinary.com/abdullah-s/image/upload/v1692825412/awssap.png",
    link: "https://www.credly.com/badges/57475b82-8e2e-4156-bc59-37e60cc218f5/public_url",
  },
  {
    name: "Certified Kubernetes Application Developer",
    date: "11/2023",
    image:
      "https://res.cloudinary.com/abdullah-s/image/upload/v1701076388/ckad_from_cncfsite_n0yhvd.png",
    link: "https://www.credly.com/badges/8f2c38b1-cb8f-4373-9c9e-c9259a7bc002/public_url",
  },
  {
    name: "AWS Authorized Instructor",
    date: "07/2023",
    image:
      "https://res.cloudinary.com/abdullah-s/image/upload/v1701075332/AAI_pafmtg.png",
    link: "https://www.credly.com/badges/de3985d7-da43-4545-9b6f-3f6922623bfa/public_url",
  },
  {
    name: "AWS Certified Solutions Architect – Associate",
    date: "01/2023",
    image:
      "https://res.cloudinary.com/abdullah-s/image/upload/v1716636759/awssaa.png",
    link: "https://www.credly.com/badges/05824927-f1d1-431c-8bd0-fc4c13f39f13/public_url",
  },
  {
    name: "Alibaba Cloud Professional Certificate (ACP)",
    date: "11/2022",
    image:
      "https://res.cloudinary.com/abdullah-s/image/upload//c_thumb,w_700,h_700,g_auto/v1692825411/ACP_ebf31z.png",
    link: "https://abdullah-alsabi-certificates.oss-me-east-1.aliyuncs.com/Abdullah-Alsabi-ACP-Certificate.png",
  },
  {
    name: "Alibaba Cloud Professional Certificate (ACA)",
    date: "11/2022",
    image:
      "https://res.cloudinary.com/abdullah-s/image/upload//c_thumb,w_700,h_700,g_auto/v1692825643/ACA_zqwbhd.png",
    link: "https://abdullah-alsabi-certificates.oss-me-east-1.aliyuncs.com/Abdullah-Alsabi-ACA-Certificate.png",
  },
  {
    name: "AWS Certified Cloud Practitioner",
    date: "10/2022",
    image:
      "https://res.cloudinary.com/abdullah-s/image/upload/v1692825411/AP_brr7ef.png",
    link: "https://www.credly.com/badges/16e593f8-070c-4104-b179-a9ebf853383c",
  },
  {
    name: "ITIL Foundations v4",
    date: "06/2022",
    image:
      "https://res.cloudinary.com/abdullah-s/image/upload/v1692825644/ITIL_ztx33v.png",
    link: "https://drive.google.com/file/d/18dnm2Wy1s7d1yD3jPAQponklEM9xO7Rs/view?usp=sharing",
  },
];
