/* eslint-disable jsx-a11y/img-redundant-alt */
import { BriefcaseIcon } from "@heroicons/react/solid";
import React from "react";
import { Certifications } from "../data";

function Certs() {
  return (
    <div
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="4000"
      data-aos-once="true"
    >
      <div id="certs"></div>
      <section className="lg:mt-10 ">
        <div className="container px-5 py-10 mx-auto">
          <div className="text-center mb-20">
            <BriefcaseIcon className="w-10 inline-block mb-4 lg:mt-10" />
            <h1 className="sm:text-4xl text-3xl font-medium title-font text-white mb-4">
              Certificates
            </h1>
            <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto">
              I've got certificates in many fields, you can click on the image
              for more details.{" "}
            </p>
          </div>
          <div className="flex flex-wrap lg:w-4/5 sm:mx-auto sm:mb-2 -mx-2">
            {Certifications.map((cert) => (
              <div key={cert.name} className="p-2 sm:w-full w-full">
                <div className="bg-gray-800 rounded flex  p-4 h-full items-center">
                  <a href={cert.link} target="_blank" rel="noopener noreferrer">
                    <img
                      className=" w-24 h-24 flex-shrink-0 mr-4"
                      alt=" Certified Image"
                      src={cert.image}
                    />
                  </a>
                  <div className="flex justify-between w-full ">
                    <span className="title-font font-medium text-white ">
                      {cert.name}
                    </span>
                    <span className="title-font font-medium text-white ">
                      {cert.date}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Certs;
