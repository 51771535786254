import React from "react";
import { FiGithub } from "react-icons/fi";
import { FiLinkedin } from "react-icons/fi";

function About() {
  return (
    <section id="about">
      <div
        data-aos="fade-up"
        data-aos-once="true"
        data-aos-easing="linear"
        data-aos-duration="4000"
        className="container mx-auto flex px-10 py-20 md:flex-row flex-col items-center"
      >
        <div className="lg:flex-grow md:w-1/2 lg:pr-24 md:pr-16 flex flex-col md:items-start md:text-left mb-16 md:mb-0 items-center text-center">
          <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-white">
            Hi, I'm Abdullah.
            <br className=" lg:inline-block" />
            Cloud/DevOps Engineer
          </h1>
          <p className="mb-8 leading-relaxed">
          Motivated and results-driven Cloud/DevOps engineer experienced in designing and deploying scalable, secure and innovative solutions. Proficient in cloud computing, automation and software development, with a strong focus on driving successful projects. A collaborative team player with a passion for delivering exceptional outcomes that exceed business objectives. Committed to continuous learning and growth in the pursuit of architectural excellence.
          </p>
          <div className="flex justify-center">
            <a
              href="#contact"
              className=" inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg"
            >
              Work Together
            </a>
            <a
              href="#projects"
              className="ml-4 inline-flex text-white bg-green-500 border-0 py-2 px-6 focus:outline-none hover:bg-green-600 rounded text-lg"
            >
              See My Projects
            </a>
          </div>
          <div className="flex justify-center mt-5">
            <a
              download
              href="/files/Abdullah-Alsabi-Resume.pdf"
              className="inline-flex  text-white bg-green-500 border-0 py-2 px-4 focus:outline-none hover:bg-green-600 rounded text-lg"
            >
              Download My Resume
            </a>
            <a
              href="https://www.linkedin.com/in/abdullah-alsabi/"
              target="_blank"
              rel="noreferrer"
              className="justify-center items-center ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-4 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg"
            >
              <FiLinkedin className=" fill-current w-6 h-6   " />
            </a>
            <a
              href="https://github.com/Abdullah-Alsabi"
              target="_blank"
              rel="noreferrer"
              className="justify-center items-center ml-4 inline-flex text-gray-400 bg-gray-800 border-0 py-2 px-4 focus:outline-none hover:bg-gray-700 hover:text-white rounded text-lg"
            >
              <FiGithub className=" fill-current w-6 h-6 " />
            </a>
          </div>
        </div>

        <div className="lg:max-w-xs lg:w-full md:w-1/2 md:max-w-xs w-5/6">
          <img
            src="https://res.cloudinary.com/abdullah-s/image/upload/v1692822189/Abdullah-Alsabi-PP_1_e7mntx.jpg"
            className="object-cover object-center rounded"
            alt="Abdullah"
          />
        </div>
      </div>
    </section>
  );
}

export default About;
