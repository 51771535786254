import React from "react";
import { BsFillArrowUpCircleFill } from "react-icons/bs";

function Contact() {
  return (
    <div
      data-aos="fade-up"
      data-aos-easing="linear"
      data-aos-duration="4000"
      data-aos-once="true"
    >
      <div id="contact"></div>
      <div className="bg-gray-900 flex justify-center text-center rounded mt-4 ">
        <div className=" px-6 mt-4 shadow-md">
          <h2 className="title-font font-semibold text-white tracking-widest text-xs">
            EMAIL
          </h2>
          <p className="text-indigo-400 leading-relaxed">
            <a href="mailto:AbdullahB.Alsabi@gmail.com">
              AbdullahB.Alsabi@gmail.com
            </a>
          </p>
        </div>
      </div>
      <section className="relative">
        <div className="container px-5 py-10 mx-auto flex sm:flex-nowrap flex-wrap flex-col md:flex-row">
          <div className="lg:w-full md:w-full bg-gray-900 rounded-lg overflow-hidden sm:mr-10 p-10 flex items-end justify-start relative">
            <iframe
              width="100%"
              height="100%"
              title="map"
              className="absolute inset-0"
              frameBorder={0}
              marginHeight={0}
              marginWidth={0}
              style={{ filter: "opacity(0.7)" }}
              src="https://www.google.com/maps/embed/v1/place?q=Riyadh&key=AIzaSyBFw0Qbyq9zTFTd-tUY6dZWTgaQzuU17R8"
            />
            <div className=" relative flex flex-wrap py-6 rounded  mt-32 ">
              <div className="lg:w-full px-6 mt-4 lg:mt-0 "></div>
            </div>
          </div>
        </div>
        <div className="container px-5 py-5 mx-auto flex justify-center sm:flex-nowrap flex-wrap">
          <BsFillArrowUpCircleFill
            className="text-4xl animate-bounce text-green-500 hover:scale-105 hover:cursor-pointer"
            onClick={() => {
              window.scroll({ top: 0, left: 0, behavior: "smooth" });
            }}
          />
        </div>
      </section>
    </div>
  );
}

export default Contact;
